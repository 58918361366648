import $ from 'jquery'
import { saveAs } from 'file-saver'
import posters from './posters'

class ItSecurityCampaignGenerator {
  constructor() {
    this.options = {
      currentStep: 1,
      maxSteps: 4,
      language : '',
      languageDefault: 'English',
      format: '',
      formatDefault: '297x420mm',
      motive: null,
      secret: 'zR33CVULulHCbZFdut1RT4YQ'
    }

    this.dropdown = {
      open: false,
      title: 'Please select',
      options: {
        openClass: 'open',
        selectedClass: 'selected',
      },
      dropownTitleRow: '.dropdown__active-container',
      dropdownItem: '.dropdown__item',
    }

    this.isDev = window.location.hostname == 'localhost' 
    this.queryString = window.location.search

    this.loadLanguages()
    this.loadFormats()
    this.loadMotives()
    this.selectOnChange()
    this.clickOnDownload()
    this.clickOnMotif()
    this.selectItem()
    this.clickDropdown()
  }

  loadLanguages() {
    const $container = $('.dropdown[data-name="language"] .dropdown__container')
    const $select = $('select[name="language"]')
    $container.find('.dropdown__item:not([data-value=""])').remove()
    $select.find('option:not([value=""])').remove()
    for (const [language, formats] of Object.entries(posters)) {
      const disabled = !formats
      $container.append(`<div class="dropdown__item${disabled ? ' disabled' : ''}" data-value="${language}">${language}</div>`);
      $select.append(`<option value="${language}"${disabled ? ' disabled' : ''}>${language}</option>`);
    }
  }
    
  loadFormats() {
    const $container = $('.dropdown[data-name="format"] .dropdown__container')
    const $select = $('select[name="format"]')
    $container.find('.dropdown__item:not([data-value=""])').remove()
    $select.find('option:not([value=""])').remove()
    
    if (this.options.language) {
      for (const [format, motives] of Object.entries(posters[this.options.language])) {
        const disabled = !motives
        $container.append(`<div class="dropdown__item${disabled ? ' disabled' : ''}" data-value="${format}">${format}</div>`);
        $select.append(`<option value="${format}"${disabled ? ' disabled' : ''}>${format}</option>`);
      }
    }
  }
    
  loadMotives() {
    const $container = $('.motif-row')
    const $select = $('select[name="motive"]')
    $container.find('.motif-row__image').remove()
    $select.find('option:not([value=""])').remove()
    
    const motives = posters[this.options.language || this.options.languageDefault][this.options.format || this.options.formatDefault]
    for (const [title, motive] of Object.entries(motives)) {
      $container.append(`<div class="motif-row__image" data-value="${title}"><img alt="" src="${motive['thumbnail']}" /><span>${title}</span></div>`);
      $select.append(`<option value="${title}"></option>`);
    }
  }

  clickOnMotif() {
    $(document).on('click', '.motif-row__image', (e) => {
      $('.motif-row__image').removeClass('active')
      const $e = $(e.currentTarget)
      $('select[name="motive"]').val($e.attr('data-value')).change()
      $e.addClass('active')
    }) 
  }


  setFormValue(name, value, step) {
    $(`form input[name="${name}"]`).val(value)
    this.options['currentStep'] = step
    this.options[name] = value

    if (name == 'language') {
      this.loadFormats()
    }
    
    if (name == 'language' || name == 'format') {
      this.loadMotives()
    }

    this.loadPdf(step, name)
  }

  toggleDropdown(dropdown, open = false) {
    if(open) {
      dropdown.parent().removeClass(this.dropdown.options.openClass)
    } else {
      dropdown.parent().addClass(this.dropdown.options.openClass)
    }
    this.dropdown.open = !this.dropdown.open
  }

  clickDropdown() {
    $(document).on('click', this.dropdown.dropownTitleRow, (e) => {
      const $dropdownTitle = $(e.currentTarget)
      $('div.dropdown.open').removeClass(this.dropdown.options.openClass)
      this.toggleDropdown($dropdownTitle, this.dropdown.open)
    })
  }

  selectItem() {
    $(document).on('click', this.dropdown.dropdownItem, (e) => {
      const $e = $(e.currentTarget)

      if ($e.is('.disabled')) {
        return;
      }

      const value = $e.data('value')
      const currentStep = parseInt($e.parent().parent().attr('data-step'))
      const name = $e.parent().parent().attr('data-name')

      $e.parent().find('.dropdown__item').removeClass(this.dropdown.options.selectedClass)
      $e.addClass(this.dropdown.options.selectedClass)

      this.toggleDropdown($e.parent(), this.dropdown.open)
      this.dropdownLabelActive($e.parent(), $e.html())

      $(`select[name="${name}"]`).val(value).change()
    })
  }

  dropdownLabelActive(dropdown, label) {
    dropdown.parent().find('.dropdown__title').text(label)
  }

  selectOnChange() {
    $(document).on('change', '.form select', (e) => {
      $('.motif-row__image').removeClass('active')
      const $selectField = $(e.currentTarget)
      const name = $selectField.attr('name')
      const value = $selectField.val()
      const currentStep = parseInt($selectField.attr('data-step'))

      this.setStatusLastStep(0)
      this.options[name] = value

      if ( value == 0 ) {
        this.setActiveStepRing(currentStep, 0)
        for ( let i = 4; i > currentStep; i-- ) {
          this.setActiveStepRing(i, 0)
          this.deactivateStep(i)
        }
      } else {
        for ( let i = 4; i > currentStep; i-- ) {
          this.setActiveStepRing(i, 0)
          this.deactivateStep(i)
        }
      }
      this.setFormValue(name, value, currentStep)
    }) 
  }

  clickOnDownload() {
    $(document).on('click', '.form__row[data-step="4"]', (e) => {
      const $e = $(e.currentTarget)
      if ( $e.attr('data-active') == 0 ) {
        e.preventDefault()
        return;
      }

      const url = `download.php?pdfname=${this.getPdf()}&secret=${this.options.secret}`
      saveAs.saveAs(url, this.getFilename())
    })
  }

  getPreview() {
    return posters[this.options.language][this.options.format][this.options.motive]['preview']
  }

  getPdf() {
    return posters[this.options.language][this.options.format][this.options.motive]['pdf']
  }

  getFilename() {
    return this.getPdf().toString().replace(/^.*\//, '').replace(/\.[^.]+\.pdf$/, '.pdf')
  }

  loadPdf(step) {
    if ( step == 1 ) {
      this.hidePreviewDiv(true)
      if ( this.options.language != 0 && this.options.language != null ) {
        this.activateNextStep(step)
      }
    } else if ( step == 2 ) {
      this.hidePreviewDiv(true)
      if ( this.options.format != 0 && this.options.format != null ) {
        this.activateNextStep(step)
      }
    } else {
      if ( this.options.motive != null && this.options.motive != 0 ) {
        this.setLoadingScreen(1)

        $('#image').attr('src', this.getPreview())
        this.hidePreviewDiv();
        this.setLoadingScreen(0)

        this.activateNextStep(step)
        if ( step == 4) {
          this.setStatusLastStep(1)
        }
      } else {
        this.hidePreviewDiv(true)
      }
    }
  }

  hidePreviewDiv(hide = false) {
    const $previewInfoDiv = $('.preview__no-preview')
    const $previewDiv = $('.preview__file')
    const $previewDivPDFContainer = $('.preview__pdf')

    if ( hide ) {
      $previewDiv.hide()
      $previewInfoDiv.show()    
      $previewDivPDFContainer.addClass('with-border')
    } else {
      $previewDiv.show()
      $previewInfoDiv.hide()
      $previewDivPDFContainer.removeClass('with-border')
    }
  }

  setStatusLastStep(status) {
    const $lastStep = $(`.form__row[data-step="6"]`)
    const $lastStepShadow = $(`.form__row[data-step="6"] .form__shadow`)
    const $beforeDivider = $(`.form__divider[data-step="5_6"]`)

    $lastStep.attr('data-active', status)        
    $beforeDivider.attr('data-active', status)
    if ( status == 0 ) {
      $lastStepShadow.show()
    } else {
      $lastStepShadow.hide()
    }
  }

  activateNextStep(currentStep) {
    const nextStep = parseInt(currentStep) + 1 
    const $nextFormRow = $(`.form__row[data-step="${nextStep}"]`)
    const $nextDivider = $(`.form__divider[data-step="${currentStep}-${nextStep}"]`)
    const $currentFormRow = $(`.form__row[data-step="${currentStep}"]`)

    if ( nextStep <= this.options.maxSteps && $currentFormRow.find('select').val() != 0 ) {
      $nextDivider.attr('data-active', 1)
      $nextFormRow.attr('data-active', 1)
      this.setActiveStepRing(currentStep, 1)
    }
  }

  deactivateStep(step) {
    this.options['currentStep'] = step
    const $currentFormRow = $(`.form__row[data-step="${step}"]`)
    const $previousDivider = $(`.form__divider[data-step="${step -1}-${step}"]`)
    const name = $currentFormRow.find('select').attr('name')

    $currentFormRow.attr('data-active', 0)
    $currentFormRow.find('select').val(0)
    $previousDivider.attr('data-active', 0)

    $(`form input[name="${name}"]`).val(0)
    this.options[name] = 0
  }

  setActiveStepRing(step, status) {
    $(`.form__row[data-step="${step}"]`).find('.form__step').attr('data-active', status)
    $(`.form__row[data-step="${step}"]`).find('.form__divider').attr('data-active', status)
  }

  setLoadingScreen(status) {
    const $loadingField = $('.preview__loading')
    status == 0 ? $loadingField.removeClass('load') : $loadingField.addClass('load')
  }


}


window.onload = () => {
  new ItSecurityCampaignGenerator
}