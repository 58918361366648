export default {
  'Deutsch': {
    '11x17inch': null,
    '297x420mm': {
      'Generic': {
        'preview': new URL('../assets/posters/de_297x420mm_generic.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_297x420mm_generic.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_297x420mm_generic.pdf', import.meta.url),
      },
      'Confidential Data': {
        'preview': new URL('../assets/posters/de_297x420mm_confidential_data.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_297x420mm_confidential_data.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_297x420mm_confidential_data.pdf', import.meta.url),
      },
      'Phishing': {
        'preview': new URL('../assets/posters/de_297x420mm_phishing.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_297x420mm_phishing.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_297x420mm_phishing.pdf', import.meta.url),
      },
      'Secure Password': {
        'preview': new URL('../assets/posters/de_297x420mm_secure_password.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_297x420mm_secure_password.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_297x420mm_secure_password.pdf', import.meta.url),
      },
      'Work Device': {
        'preview': new URL('../assets/posters/de_297x420mm_work_device.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_297x420mm_work_device.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_297x420mm_work_device.pdf', import.meta.url),
      },
      'Work Station': {
        'preview': new URL('../assets/posters/de_297x420mm_work_station.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_297x420mm_work_station.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_297x420mm_work_station.pdf', import.meta.url),
      },
    },
    '700x1000mm': {
      'Generic': {
        'preview': new URL('../assets/posters/de_700x1000mm_generic.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_700x1000mm_generic.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_700x1000mm_generic.pdf', import.meta.url),
      },
      'Confidential Data': {
        'preview': new URL('../assets/posters/de_700x1000mm_confidential_data.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_700x1000mm_confidential_data.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_700x1000mm_confidential_data.pdf', import.meta.url),
      },
      'Phishing': {
        'preview': new URL('../assets/posters/de_700x1000mm_phishing.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_700x1000mm_phishing.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_700x1000mm_phishing.pdf', import.meta.url),
      },
      'Secure Password': {
        'preview': new URL('../assets/posters/de_700x1000mm_secure_password.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_700x1000mm_secure_password.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_700x1000mm_secure_password.pdf', import.meta.url),
      },
      'Work Device': {
        'preview': new URL('../assets/posters/de_700x1000mm_work_device.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_700x1000mm_work_device.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_700x1000mm_work_device.pdf', import.meta.url),
      },
      'Work Station': {
        'preview': new URL('../assets/posters/de_700x1000mm_work_station.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/de_700x1000mm_work_station.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/de_700x1000mm_work_station.pdf', import.meta.url),
      },
    },
  },
  'English': {
    '11x17inch': {
      'Generic': {
        'preview': new URL('../assets/posters/en_11x17inch_generic.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_11x17inch_generic.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_11x17inch_generic.pdf', import.meta.url),
      },
      'Confidential Data': {
        'preview': new URL('../assets/posters/en_11x17inch_confidential_data.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_11x17inch_confidential_data.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_11x17inch_confidential_data.pdf', import.meta.url),
      },
      'Phishing': {
        'preview': new URL('../assets/posters/en_11x17inch_phishing.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_11x17inch_phishing.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_11x17inch_phishing.pdf', import.meta.url),
      },
      'Secure Password': {
        'preview': new URL('../assets/posters/en_11x17inch_secure_password.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_11x17inch_secure_password.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_11x17inch_secure_password.pdf', import.meta.url),
      },
      'Work Device': {
        'preview': new URL('../assets/posters/en_11x17inch_work_device.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_11x17inch_work_device.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_11x17inch_work_device.pdf', import.meta.url),
      },
      'Work Station': {
        'preview': new URL('../assets/posters/en_11x17inch_work_station.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_11x17inch_work_station.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_11x17inch_work_station.pdf', import.meta.url),
      },
    },
    '297x420mm': {
      'Generic': {
        'preview': new URL('../assets/posters/en_297x420mm_generic.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_297x420mm_generic.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_297x420mm_generic.pdf', import.meta.url),
      },
      'Confidential Data': {
        'preview': new URL('../assets/posters/en_297x420mm_confidential_data.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_297x420mm_confidential_data.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_297x420mm_confidential_data.pdf', import.meta.url),
      },
      'Phishing': {
        'preview': new URL('../assets/posters/en_297x420mm_phishing.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_297x420mm_phishing.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_297x420mm_phishing.pdf', import.meta.url),
      },
      'Secure Password': {
        'preview': new URL('../assets/posters/en_297x420mm_secure_password.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_297x420mm_secure_password.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_297x420mm_secure_password.pdf', import.meta.url),
      },
      'Work Device': {
        'preview': new URL('../assets/posters/en_297x420mm_work_device.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_297x420mm_work_device.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_297x420mm_work_device.pdf', import.meta.url),
      },
      'Work Station': {
        'preview': new URL('../assets/posters/en_297x420mm_work_station.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_297x420mm_work_station.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_297x420mm_work_station.pdf', import.meta.url),
      },
    },
    '700x1000mm': {
      'Generic': {
        'preview': new URL('../assets/posters/en_700x1000mm_generic.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_700x1000mm_generic.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_700x1000mm_generic.pdf', import.meta.url),
      },
      'Confidential Data': {
        'preview': new URL('../assets/posters/en_700x1000mm_confidential_data.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_700x1000mm_confidential_data.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_700x1000mm_confidential_data.pdf', import.meta.url),
      },
      'Phishing': {
        'preview': new URL('../assets/posters/en_700x1000mm_phishing.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_700x1000mm_phishing.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_700x1000mm_phishing.pdf', import.meta.url),
      },
      'Secure Password': {
        'preview': new URL('../assets/posters/en_700x1000mm_secure_password.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_700x1000mm_secure_password.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_700x1000mm_secure_password.pdf', import.meta.url),
      },
      'Work Device': {
        'preview': new URL('../assets/posters/en_700x1000mm_work_device.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_700x1000mm_work_device.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_700x1000mm_work_device.pdf', import.meta.url),
      },
      'Work Station': {
        'preview': new URL('../assets/posters/en_700x1000mm_work_station.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/en_700x1000mm_work_station.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/en_700x1000mm_work_station.pdf', import.meta.url),
      },
    },
  },
  'Español (Latam)': {
    '11x17inch': null,
    '297x420mm': {
      'Generic': {
        'preview': new URL('../assets/posters/es_297x420mm_generic.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_297x420mm_generic.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_297x420mm_generic.pdf', import.meta.url),
      },
      'Confidential Data': {
        'preview': new URL('../assets/posters/es_297x420mm_confidential_data.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_297x420mm_confidential_data.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_297x420mm_confidential_data.pdf', import.meta.url),
      },
      'Phishing': {
        'preview': new URL('../assets/posters/es_297x420mm_phishing.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_297x420mm_phishing.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_297x420mm_phishing.pdf', import.meta.url),
      },
      'Secure Password': {
        'preview': new URL('../assets/posters/es_297x420mm_secure_password.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_297x420mm_secure_password.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_297x420mm_secure_password.pdf', import.meta.url),
      },
      'Work Device': {
        'preview': new URL('../assets/posters/es_297x420mm_work_device.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_297x420mm_work_device.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_297x420mm_work_device.pdf', import.meta.url),
      },
      'Work Station': {
        'preview': new URL('../assets/posters/es_297x420mm_work_station.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_297x420mm_work_station.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_297x420mm_work_station.pdf', import.meta.url),
      },
    },
    '700x1000mm': {
      'Generic': {
        'preview': new URL('../assets/posters/es_700x1000mm_generic.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_700x1000mm_generic.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_700x1000mm_generic.pdf', import.meta.url),
      },
      'Confidential Data': {
        'preview': new URL('../assets/posters/es_700x1000mm_confidential_data.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_700x1000mm_confidential_data.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_700x1000mm_confidential_data.pdf', import.meta.url),
      },
      'Phishing': {
        'preview': new URL('../assets/posters/es_700x1000mm_phishing.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_700x1000mm_phishing.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_700x1000mm_phishing.pdf', import.meta.url),
      },
      'Secure Password': {
        'preview': new URL('../assets/posters/es_700x1000mm_secure_password.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_700x1000mm_secure_password.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_700x1000mm_secure_password.pdf', import.meta.url),
      },
      'Work Device': {
        'preview': new URL('../assets/posters/es_700x1000mm_work_device.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_700x1000mm_work_device.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_700x1000mm_work_device.pdf', import.meta.url),
      },
      'Work Station': {
        'preview': new URL('../assets/posters/es_700x1000mm_work_station.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/es_700x1000mm_work_station.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/es_700x1000mm_work_station.pdf', import.meta.url),
      },
    },
  },
  'Français': {
    '11x17inch': null,
    '297x420mm': {
      'Generic': {
        'preview': new URL('../assets/posters/fr_297x420mm_generic.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_297x420mm_generic.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_297x420mm_generic.pdf', import.meta.url),
      },
      'Confidential Data': {
        'preview': new URL('../assets/posters/fr_297x420mm_confidential_data.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_297x420mm_confidential_data.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_297x420mm_confidential_data.pdf', import.meta.url),
      },
      'Phishing': {
        'preview': new URL('../assets/posters/fr_297x420mm_phishing.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_297x420mm_phishing.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_297x420mm_phishing.pdf', import.meta.url),
      },
      'Secure Password': {
        'preview': new URL('../assets/posters/fr_297x420mm_secure_password.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_297x420mm_secure_password.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_297x420mm_secure_password.pdf', import.meta.url),
      },
      'Work Device': {
        'preview': new URL('../assets/posters/fr_297x420mm_work_device.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_297x420mm_work_device.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_297x420mm_work_device.pdf', import.meta.url),
      },
      'Work Station': {
        'preview': new URL('../assets/posters/fr_297x420mm_work_station.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_297x420mm_work_station.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_297x420mm_work_station.pdf', import.meta.url),
      },
    },
    '700x1000mm': {
      'Generic': {
        'preview': new URL('../assets/posters/fr_700x1000mm_generic.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_700x1000mm_generic.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_700x1000mm_generic.pdf', import.meta.url),
      },
      'Confidential Data': {
        'preview': new URL('../assets/posters/fr_700x1000mm_confidential_data.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_700x1000mm_confidential_data.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_700x1000mm_confidential_data.pdf', import.meta.url),
      },
      'Phishing': {
        'preview': new URL('../assets/posters/fr_700x1000mm_phishing.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_700x1000mm_phishing.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_700x1000mm_phishing.pdf', import.meta.url),
      },
      'Secure Password': {
        'preview': new URL('../assets/posters/fr_700x1000mm_secure_password.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_700x1000mm_secure_password.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_700x1000mm_secure_password.pdf', import.meta.url),
      },
      'Work Device': {
        'preview': new URL('../assets/posters/fr_700x1000mm_work_device.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_700x1000mm_work_device.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_700x1000mm_work_device.pdf', import.meta.url),
      },
      'Work Station': {
        'preview': new URL('../assets/posters/fr_700x1000mm_work_station.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/fr_700x1000mm_work_station.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/fr_700x1000mm_work_station.pdf', import.meta.url),
      },
    },
  },
  'Italiano': {
    '11x17inch': null,
    '297x420mm': {
      'Generic': {
        'preview': new URL('../assets/posters/it_297x420mm_generic.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_297x420mm_generic.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_297x420mm_generic.pdf', import.meta.url),
      },
      'Confidential Data': {
        'preview': new URL('../assets/posters/it_297x420mm_confidential_data.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_297x420mm_confidential_data.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_297x420mm_confidential_data.pdf', import.meta.url),
      },
      'Phishing': {
        'preview': new URL('../assets/posters/it_297x420mm_phishing.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_297x420mm_phishing.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_297x420mm_phishing.pdf', import.meta.url),
      },
      'Secure Password': {
        'preview': new URL('../assets/posters/it_297x420mm_secure_password.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_297x420mm_secure_password.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_297x420mm_secure_password.pdf', import.meta.url),
      },
      'Work Device': {
        'preview': new URL('../assets/posters/it_297x420mm_work_device.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_297x420mm_work_device.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_297x420mm_work_device.pdf', import.meta.url),
      },
      'Work Station': {
        'preview': new URL('../assets/posters/it_297x420mm_work_station.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_297x420mm_work_station.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_297x420mm_work_station.pdf', import.meta.url),
      },
    },
    '700x1000mm': {
      'Generic': {
        'preview': new URL('../assets/posters/it_700x1000mm_generic.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_700x1000mm_generic.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_700x1000mm_generic.pdf', import.meta.url),
      },
      'Confidential Data': {
        'preview': new URL('../assets/posters/it_700x1000mm_confidential_data.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_700x1000mm_confidential_data.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_700x1000mm_confidential_data.pdf', import.meta.url),
      },
      'Phishing': {
        'preview': new URL('../assets/posters/it_700x1000mm_phishing.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_700x1000mm_phishing.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_700x1000mm_phishing.pdf', import.meta.url),
      },
      'Secure Password': {
        'preview': new URL('../assets/posters/it_700x1000mm_secure_password.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_700x1000mm_secure_password.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_700x1000mm_secure_password.pdf', import.meta.url),
      },
      'Work Device': {
        'preview': new URL('../assets/posters/it_700x1000mm_work_device.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_700x1000mm_work_device.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_700x1000mm_work_device.pdf', import.meta.url),
      },
      'Work Station': {
        'preview': new URL('../assets/posters/it_700x1000mm_work_station.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/it_700x1000mm_work_station.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/it_700x1000mm_work_station.pdf', import.meta.url),
      },
    },
  },
  'Português (Brasil)': {
    '11x17inch': null,
    '297x420mm': {
      'Generic': {
        'preview': new URL('../assets/posters/pt_297x420mm_generic.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_297x420mm_generic.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_297x420mm_generic.pdf', import.meta.url),
      },
      'Confidential Data': {
        'preview': new URL('../assets/posters/pt_297x420mm_confidential_data.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_297x420mm_confidential_data.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_297x420mm_confidential_data.pdf', import.meta.url),
      },
      'Phishing': {
        'preview': new URL('../assets/posters/pt_297x420mm_phishing.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_297x420mm_phishing.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_297x420mm_phishing.pdf', import.meta.url),
      },
      'Secure Password': {
        'preview': new URL('../assets/posters/pt_297x420mm_secure_password.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_297x420mm_secure_password.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_297x420mm_secure_password.pdf', import.meta.url),
      },
      'Work Device': {
        'preview': new URL('../assets/posters/pt_297x420mm_work_device.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_297x420mm_work_device.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_297x420mm_work_device.pdf', import.meta.url),
      },
      'Work Station': {
        'preview': new URL('../assets/posters/pt_297x420mm_work_station.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_297x420mm_work_station.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_297x420mm_work_station.pdf', import.meta.url),
      },
    },
    '700x1000mm': {
      'Generic': {
        'preview': new URL('../assets/posters/pt_700x1000mm_generic.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_700x1000mm_generic.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_700x1000mm_generic.pdf', import.meta.url),
      },
      'Confidential Data': {
        'preview': new URL('../assets/posters/pt_700x1000mm_confidential_data.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_700x1000mm_confidential_data.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_700x1000mm_confidential_data.pdf', import.meta.url),
      },
      'Phishing': {
        'preview': new URL('../assets/posters/pt_700x1000mm_phishing.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_700x1000mm_phishing.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_700x1000mm_phishing.pdf', import.meta.url),
      },
      'Secure Password': {
        'preview': new URL('../assets/posters/pt_700x1000mm_secure_password.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_700x1000mm_secure_password.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_700x1000mm_secure_password.pdf', import.meta.url),
      },
      'Work Device': {
        'preview': new URL('../assets/posters/pt_700x1000mm_work_device.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_700x1000mm_work_device.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_700x1000mm_work_device.pdf', import.meta.url),
      },
      'Work Station': {
        'preview': new URL('../assets/posters/pt_700x1000mm_work_station.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/pt_700x1000mm_work_station.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/pt_700x1000mm_work_station.pdf', import.meta.url),
      },
    },
  },
  '日本語': {
    '11x17inch': null,
    '297x420mm': {
      'Generic': {
        'preview': new URL('../assets/posters/jp_297x420mm_generic.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_297x420mm_generic.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_297x420mm_generic.pdf', import.meta.url),
      },
      'Confidential Data': {
        'preview': new URL('../assets/posters/jp_297x420mm_confidential_data.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_297x420mm_confidential_data.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_297x420mm_confidential_data.pdf', import.meta.url),
      },
      'Phishing': {
        'preview': new URL('../assets/posters/jp_297x420mm_phishing.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_297x420mm_phishing.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_297x420mm_phishing.pdf', import.meta.url),
      },
      'Secure Password': {
        'preview': new URL('../assets/posters/jp_297x420mm_secure_password.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_297x420mm_secure_password.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_297x420mm_secure_password.pdf', import.meta.url),
      },
      'Work Device': {
        'preview': new URL('../assets/posters/jp_297x420mm_work_device.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_297x420mm_work_device.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_297x420mm_work_device.pdf', import.meta.url),
      },
      'Work Station': {
        'preview': new URL('../assets/posters/jp_297x420mm_work_station.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_297x420mm_work_station.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_297x420mm_work_station.pdf', import.meta.url),
      },
    },
    '700x1000mm': {
      'Generic': {
        'preview': new URL('../assets/posters/jp_700x1000mm_generic.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_700x1000mm_generic.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_700x1000mm_generic.pdf', import.meta.url),
      },
      'Confidential Data': {
        'preview': new URL('../assets/posters/jp_700x1000mm_confidential_data.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_700x1000mm_confidential_data.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_700x1000mm_confidential_data.pdf', import.meta.url),
      },
      'Phishing': {
        'preview': new URL('../assets/posters/jp_700x1000mm_phishing.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_700x1000mm_phishing.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_700x1000mm_phishing.pdf', import.meta.url),
      },
      'Secure Password': {
        'preview': new URL('../assets/posters/jp_700x1000mm_secure_password.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_700x1000mm_secure_password.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_700x1000mm_secure_password.pdf', import.meta.url),
      },
      'Work Device': {
        'preview': new URL('../assets/posters/jp_700x1000mm_work_device.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_700x1000mm_work_device.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_700x1000mm_work_device.pdf', import.meta.url),
      },
      'Work Station': {
        'preview': new URL('../assets/posters/jp_700x1000mm_work_station.jpg', import.meta.url),
        'thumbnail': new URL('../assets/posters/jp_700x1000mm_work_station.jpg?width=250', import.meta.url),
        'pdf': new URL('../assets/posters/jp_700x1000mm_work_station.pdf', import.meta.url),
      },
    },
  }
};

